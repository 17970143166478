<template>
  <div v-if="model">
    <img src="/images/bg/news/news-bg.png" class="news-bg static" alt="Haber" />
    <div class="newsMain">
      <div class="news-title wow fadeInDown" data-wow-delay="0.3s">
        <strong>Magazin</strong>
      </div>
      <div class="news-text wow fadeInUp" data-wow-delay="1.2s">
        <span>{{ model.description }}</span>
      </div>
      <div class="news-image wow fadeInUp" data-wow-delay="2s">
        <img :src="imagePath" alt="Haber" />
      </div>
    </div>
  </div>
</template>

<script>
import WOW from 'wow.js';
import store from '../store/index';
new WOW().init();
export default {
  name: 'news-magazin',
  props: ['data'],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch('setNextPlaylist');
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.news;
    },
    imagePath() {
      return `${process.env.VUE_APP_BASE_CDN_URL}/${this.model.imageUrl}`;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/animate/animate.css';
@import '../assets/scss/news.scss';
.newsMain {
  .news-title {
    background-image: url('/images/bg/news/news-magazine-title.png');
  }
  .news-text {
    background-image: url('/images/bg/news/news-magazine-text.png');
    max-width: 60%;
    span {
      max-height: 129px;
      -webkit-line-clamp: 3;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
